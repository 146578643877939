<template>
  <div class="flex items-center justify-center flex-wrap bg-grey-100 p-6">
    <h1>Page Not Found</h1>
  </div>
</template>

<script>
export default {
  name: '404',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
